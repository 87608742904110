import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Navigation from './components/Navigation';
import MobileNavigation from './components/MobileNavigation';
import MobileToggle from './components/MobileToggle';

const HeaderComponent = () => {
	const [mobileNavActive, setMobileNavActive] = useState(false);

	useEffect(() => {
		if (typeof window !== `undefined`) {
			window.addEventListener('resize', () => {
				if (window.innerWidth > 768) {
					setMobileNavActive(false);
				}
			});
		}
	}, []);

	useEffect(() => {
		if (mobileNavActive) document.body.style.overflow = 'hidden';
		if (!mobileNavActive) document.body.style.overflow = 'auto';
	}, [mobileNavActive]);

	return (
		<Header id="header">
			<Navigation />
			<MobileToggle open={mobileNavActive} setOpen={setMobileNavActive} />
			<MobileNavigation
				activeState={mobileNavActive}
				setActiveState={setMobileNavActive}
			/>
		</Header>
	);
};

export default HeaderComponent;

const Header = styled.header`
	position: relative;
	width: 100%;
	height: 80px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 40px;
	border-bottom: 1px solid rgba(229, 231, 235, 1);
	@media (max-width: 768px) {
		padding: 0px 16px;
	}
`;

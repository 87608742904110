import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

const Seo = () => {
	const { site } = useStaticQuery(seoQuery);
	return (
		<Helmet htmlAttributes={{ lang: site.siteMetadata.siteLanguage }}>
			<title>{site.siteMetadata.title}</title>
			<meta name="description" content={site.siteMetadata.description} />
			<meta name="keywords" content={site.siteMetadata.keywords} />
			<meta name="author" content={site.siteMetadata.author} />
			<meta property="og:title" content={site.siteMetadata.title} />
			<meta property="og:description" content={site.siteMetadata.description} />
			<meta property="og:url" content={site.siteMetadata.siteUrl} />
			<meta property="og:type" content="website" />
			<meta property="og:site_name" content={site.siteMetadata.title} />
			<meta property="og:locale" content={site.siteMetadata.siteLanguage} />
		</Helmet>
	);
};

export default Seo;

const seoQuery = graphql`
	query seoQuery {
		site {
			siteMetadata {
				siteUrl
				title
				description
				siteLanguage
				ogLanguage
				author
				twitter
				keywords
			}
		}
	}
`;

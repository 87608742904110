import React from 'react';
import { css } from 'styled-components';
import Button from '../../Button';

const NavLink = ({ to, label, type, offset }) => (
	<Button
		Element="scroll-link"
		to={to}
		variant={type !== 'link' && 'primary'}
		styles={Styles(type)}
		offset={offset}
	>
		{label}
	</Button>
);

export default NavLink;

const Styles = type => css`
	${type === 'link' && 'height: 100%;'}
	${type === 'link' ? 'padding: 0px 16px;' : 'padding: 0px 24px;'}
  font-size: 1rem;
	&:nth-child(4) {
		padding: 0px 24px 0px 16px;
	}
	&:hover {
		color: var(--primary);
	}
`;

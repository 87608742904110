import React from 'react';
import styled from 'styled-components';
import MobileNavLink from './MobileNavLink';
import { mobileData } from '../data';

const MobileNavigation = ({ activeState, setActiveState }) => (
	<Nav className={activeState ? 'active' : ''}>
		<Container>
			<List className="text-2xl text-center text-gray-800">
				{mobileData.map(({ to, label, icon }) => (
					<MobileNavLink
						key={to}
						to={to}
						label={label}
						icon={icon}
						open={activeState}
						setOpen={setActiveState}
					/>
				))}
			</List>
		</Container>
	</Nav>
);

export default MobileNavigation;

const Nav = styled.nav`
	position: fixed;
	top: 128px;
	left: 0px;
	right: 0px;
	width: 100vw;
	height: calc(100vh - 128px);
	max-height: 0px;
	opacity: 0;
	pointer-events: none;
	background: white;
	z-index: 49;
	padding: 30px;
	transition: max-height 350ms ease-in-out, opacity 300ms ease-in-out;
	&.active {
		opacity: 1;
		max-height: 1000px;
		pointer-events: all;
	}
`;
const Container = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
`;
const List = styled.div`
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	list-style: none;
`;

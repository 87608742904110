const preHeaderData = [
	{
		to: 'tel:8582327090',
		ariaLabel: 'Phone',
		icon: {
			type: 'fas',
			name: 'phone-alt',
		},
	},
	{
		to: 'mailto:jordanesguerra93@gmail.com',
		ariaLabel: 'Email',
		icon: {
			type: 'far',
			name: 'envelope',
		},
	},
	{
		to: 'https://www.linkedin.com/in/jordanesguerra/',
		ariaLabel: 'LinkedIn',
		icon: {
			type: 'fab',
			name: 'linkedin-in',
		},
	},
	{
		to: 'https://github.com/jecreative',
		ariaLabel: 'GitHub',
		icon: {
			type: 'fab',
			name: 'github',
		},
	},
];

export default preHeaderData;

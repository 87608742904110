import React from 'react';
import styled from 'styled-components';
import Icon from '../../Icon';

const MobileToggle = ({ open, setOpen }) => (
	<Toggle onClick={() => setOpen(!open)} aria-label="Toggle Mobile Nav">
		{open ? (
			<Icon type="fas" icon="times" classes="text-xl" />
		) : (
			<Icon type="fas" icon="bars" classes="text-xl" />
		)}
	</Toggle>
);

export default MobileToggle;

const Toggle = styled.button`
	z-index: 50;
	padding: 0rem 0.25rem;
	border: transparent;
	cursor: pointer;
	&:focus {
		outline: none;
	}
	@media (min-width: 768px) {
		display: none;
	}

	svg {
		max-width: 45px;
	}
`;

import styled from 'styled-components';

export const Footer = styled.footer`
	width: 100%;
	height: 48px;
	background: #1f2937;
	padding: 0px 80px;
	@media (max-width: 575px) {
		height: auto;
		padding: 20px;
	}
`;
export const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: white;
	@media (max-width: 575px) {
		flex-direction: column-reverse;
	}
`;
export const Social = styled.div`
	width: auto;
	height: 100%;
	display: flex;
	align-items: center;
	@media (max-width: 575px) {
		margin-bottom: 24px;
	}
	p {
		white-space: nowrap;
		margin-right: 8px;
	}
`;
export const Link = styled.a`
	display: flex;
	align-items: center;
	whitspace: nowrap;
	&[data-label='Phone'],
	&[data-label='Email'] {
		@media (min-width: 768px) {
			display: none;
		}
	}
	svg {
		width: 35px;
		transition: all 300ms ease-in-out;
		&:hover {
			opacity: 0.2;
		}
	}
`;

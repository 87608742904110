import React from 'react';
import loadable from '@loadable/component';

const Generator = ({ components }) => {
	if (!components) return null;
	return (
		<>
			{components.map(component => {
				const type = component.internal.type;
				switch (type) {
					case 'DatoCmsComponentHero':
						const Hero = loadable(() => import('../Hero'));
						return <Hero key={component.id} data={component} />;
					case 'DatoCmsComponentCardDeck':
						const CardDeck = loadable(() => import('../CardDeck'));
						return <CardDeck key={component.id} data={component} />;
					case 'DatoCmsComponentSwitchback':
						const Switchback = loadable(() => import('../Switchback'));
						return <Switchback key={component.id} data={component} />;
					default:
						return null;
				}
			})}
		</>
	);
};

export default Generator;

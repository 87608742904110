import React from 'react';
import styled from 'styled-components';

const Icon = ({ type, icon, color, classes = '', styles }) => {
	const iconSet = {
		fab: './sprites/brands.svg',
		far: './sprites/regular.svg',
		fas: './sprites/solid.svg',
		dev: './sprites/dev.svg',
		hobby: './sprites/hobby.svg',
	}[type];

	return (
		<Svg
			width="100"
			viewBox="0 0 1000 500"
			fill={color}
			className={classes || ''}
			styles={styles}
		>
			<use href={`${iconSet}#${icon}`} />
		</Svg>
	);
};

export default Icon;

const Svg = styled.svg`
	${props => props?.styles}
`;

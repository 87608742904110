import React from 'react';
import styled from 'styled-components';
import Icon from '../../Icon';
import preHeaderData from '../data/preheader';

const PreHeaderLink = ({ ariaLabel, to, icon }) => (
	<Link href={to} aria-label={ariaLabel} data-label={ariaLabel}>
		<Icon
			type={icon.type}
			icon={icon.name}
			color="#ffffff"
			classes="text-lg cursor-pointer hover:text-gray-400"
		/>
	</Link>
);

const PreHeader = () => (
	<Container className="bg-gray-800">
		<Contact id="preheader_left" className="text-sm text-gray-200">
			<Link href="mailto:jordanesguerra93@gmail.com">
				<Icon
					type="far"
					icon="envelope"
					color="#ffffff"
					classes="mr-3 text-sm"
				/>
				jordanesguerra93@gmail.com
			</Link>
			<span className="mx-5">|</span>
			<Link href="tel:8582327090" className="hover:text-gray-400">
				<Icon
					type="fas"
					icon="phone-alt"
					color="#ffffff"
					classes="mr-3 text-sm"
				/>
				(858) 232-7090
			</Link>
		</Contact>
		<Social id="preheader_right" className="text-sm text-gray-200">
			<p className="hidden md:block">Find me on social media</p>
			{preHeaderData.map(item => (
				<PreHeaderLink
					key={item.to}
					ariaLabel={item?.ariaLabel}
					to={item.to}
					icon={item.icon}
				/>
			))}
		</Social>
	</Container>
);

export default PreHeader;

const Container = styled.div`
	width: 100%;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 40px;
	@media (max-width: 768px) {
		justify-content: flex-end;
		padding: 0px 16px;
	}
`;
const Contact = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	@media (max-width: 768px) {
		display: none;
	}
`;
const Social = styled.div`
	width: auto;
	height: 100%;
	display: flex;
	align-items: center;
	p {
		white-space: nowrap;
		margin-right: 8px;
	}
`;
const Link = styled.a`
	display: flex;
	align-items: center;
	whitspace: nowrap;
	&[data-label='Phone'],
	&[data-label='Email'] {
		@media (min-width: 768px) {
			display: none;
		}
	}
	svg {
		width: 35px;
		transition: all 300ms ease-in-out;
		&:hover {
			opacity: 0.2;
		}
	}
`;

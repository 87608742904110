import React from 'react';
import PreHeader from '../Header/components/PreHeader';
import HeaderComponent from '../Header';
import Footer from '../Footer';
import Button from '../Button';

const Layout = ({ children }) => (
	<>
		<Button Element="scroll-button" />
		<PreHeader />
		<HeaderComponent />
		<main>{children}</main>
		<Footer />
	</>
);

export default Layout;

import { css } from 'styled-components';

const variants = {
	primary: css`
		border: 2px solid var(--primary);
		background: var(--primary);
		color: #ffffff;
		transition: all 250ms ease-in-out;
		svg use {
			transition: all 250ms ease-in-out;
		}
		&:hover {
			background: transparent;
			color: var(--primary);
			svg use {
				fill: var(--primary);
			}
		}
	`,
	secondary: css`
		border: 2px solid var(--primary);
		color: #0b1a2b;
		transition: all 250ms ease-in-out;
		svg use {
			transition: all 250ms ease-in-out;
		}
		&:hover {
			border: 2px solid var(--primary);
			background: var(--primary);
			color: #ffffff;
			svg use {
				fill: white;
			}
		}
	`,
};

export default variants;

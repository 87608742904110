import React from 'react';
import Icon from '../Icon';
import preHeaderData from '../Header/data/preheader';
import { Footer, Container, Social, Link } from './styles/index.styled';

const FooterComponent = () => (
	<Footer>
		<Container className="text-xs">
			<p>© {new Date().getFullYear()} Jordan Esguerra. All rights reserved.</p>
			<Social id="preheader_right" className="text-sm text-gray-200">
				{preHeaderData.map(item => (
					<Link
						key={item?.to}
						href={item?.to}
						aria-label={item?.ariaLabel}
						data-label={item?.ariaLabel}
					>
						<Icon
							type={item?.icon.type}
							icon={item?.icon.name}
							color="#ffffff"
							classes="text-lg cursor-pointer hover:text-gray-400"
						/>
					</Link>
				))}
			</Social>
		</Container>
	</Footer>
);

export default FooterComponent;

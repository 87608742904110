import React from 'react';
import styled from 'styled-components';

const elementStyles = {
	h1: 'text-5xl font-black sm:text-left lg:text-6xl xl:text-7xl',
	h2: 'text-5xl font-black sm:text-left lg:text-6xl xl:text-7xl',
	h3: '',
	h4: 'text-xl font-bold',
	h5: 'primary text-base font-black text-left sm:text-lg',
	h6: 'text-lg font-black',
	p: '',
	div: 'text-md',
};

const Text = ({
	Element,
	text,
	children,
	html,
	classes,
	styles,
	fontWeight,
	fontSize,
}) => {
	const StyledElement = styled(Element)`
		${fontWeight && `font-weight: ${fontWeight};`}
		${fontSize && `font-size: ${fontSize};`}
    ${props => props?.styles}
	`;

	if (Element === 'div' && html) {
		return (
			<StyledElement
				className={`${elementStyles[Element] || ''} ${classes || ''}`}
				styles={styles}
				fontSize={fontSize}
				fontWeight={fontWeight}
				dangerouslySetInnerHTML={{ __html: html }}
			/>
		);
	}

	return (
		<StyledElement
			className={`${elementStyles[Element] || ''} ${classes || ''}`}
			styles={styles}
			fontSize={fontSize}
			fontWeight={fontWeight}
		>
			{text || children}
		</StyledElement>
	);
};

export default Text;

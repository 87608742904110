import React from 'react';
import loadable from '@loadable/component';
import { css } from 'styled-components';
import variants from './styles/index.styled';

const ButtonComponent = ({
	Element,
	classes,
	text,
	children,
	to,
	variant,
	handleClick,
	styles,
	target,
	offset,
}) => {
	if (!Element) return null;
	if (Element === 'button') {
		const Button = loadable(() => import('./components/Button'));
		return (
			<Button
				text={text}
				children={children}
				classes={classes}
				styles={setStyles(variant, styles)}
				handleClick={handleClick}
			/>
		);
	}
	if (Element === 'scroll-link') {
		const ScrollLink = loadable(() => import('./components/ScrollLink'));
		return (
			<ScrollLink
				as="a"
				className={classes}
				styles={setStyles(variant, styles)}
				href="/"
				to={to}
				variant={variant}
				offset={offset}
			>
				{text}
				{children}
			</ScrollLink>
		);
	}
	if (Element === 'scroll-button') {
		const ScrollButton = loadable(() => import('./components/ScrollButton'));
		return <ScrollButton />;
	}
	if (Element === 'a') {
		const Link = loadable(() => import('./components/Link'));
		return (
			<Link
				to={to}
				classes={classes}
				styles={setStyles(variant, styles)}
				target={target || '_self'}
			>
				{text}
				{children}
			</Link>
		);
	}
	return null;
};

export default ButtonComponent;

const setStyles = (variant, styles) => css`
	display: flex;
	align-items: center;
	justify-content: center;
	width: auto;
	height: 47px;
	white-space: nowrap;
	padding: 4px 16px;
	font-size: 0.875rem;
	font-weight: bold;
	border-radius: 9999px;
	cursor: pointer;
	transition: all 250ms ease-in-out;
	${variant && variants[variant]}
	${styles && styles}
      svg {
		width: 35px;
		height: 35px;
	}
`;

export const desktopData = [
	{
		label: 'Home',
		to: 'home',
		type: 'link',
		offset: -100,
	},
	{
		label: 'About',
		to: 'DatoCmsComponentSwitchback-10301051-en',
		type: 'link',
		offset: -75,
	},
	{
		label: 'Services',
		to: 'DatoCmsComponentCardDeck-10028551-en',
		type: 'link',
		offset: 0,
	},
	{
		label: 'Portfolio',
		to: 'DatoCmsComponentCardDeck-10028400-en',
		type: 'link',
		offset: -30,
	},
	{
		label: 'Hire Me',
		to: 'DatoCmsComponentSwitchback-10301052-en',
		type: 'button',
		offset: 100,
	},
];
export const mobileData = [
	{
		label: 'Home',
		to: 'home',
		icon: {
			type: 'fas',
			class: 'home',
		},
	},
	{
		label: 'About',
		to: 'DatoCmsComponentSwitchback-10301051-en',
		icon: {
			type: 'fas',
			class: 'info-circle',
		},
	},
	{
		label: 'Services',
		to: 'DatoCmsComponentCardDeck-10028551-en',
		icon: {
			type: 'fas',
			class: 'cogs',
		},
	},
	{
		label: 'Portfolio',
		to: 'DatoCmsComponentCardDeck-10028400-en',
		icon: {
			type: 'fas',
			class: 'file-code',
		},
	},
	{
		label: 'Contact',
		to: 'DatoCmsComponentSwitchback-10301052-en',
		icon: {
			type: 'fas',
			class: 'paper-plane',
		},
	},
];

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import Icon from '../../Icon';

const MobileNavLink = ({ to, label, icon, open, setOpen }) => (
	<ScrollLink
		to={to}
		href="/"
		onClick={() => setOpen(!open)}
		className=" hover:text-gray-300"
	>
		<Icon type={icon.type} icon={icon.class} />
		{label}
	</ScrollLink>
);

export default MobileNavLink;

const ScrollLink = styled(Link)`
	width: 100%;
	height: auto;
	display: flex;
	padding: 16px 0px;
	cursor: pointer;
	border-bottom: 1px solid rgba(229, 231, 235, 1);
	transition: all 250ms ease-in-out;
	&:first-child {
		padding: 0px 0px 12px 0px;
	}
	&:focus {
		background: white;
	}
	&:hover {
		color: rgba(209, 213, 219, 1);
		svg use {
			fill: rgba(209, 213, 219, 1);
		}
	}
	svg {
		width: 40px;
		height: 40px;
		margin-right: 16px;
		transform: translateY(-2px);
		use {
			transition: all 250ms ease-in-out;
		}
	}
`;

import React from 'react';
import styled from 'styled-components';
import Image from '../../Image';
import NavLink from './NavLink';
import Logo from '../../../images/logo.svg';
import { desktopData } from '../data';

const Navigation = () => (
	<Nav className="text-base font-medium tracking-wide text-gray-500">
		<div
			id="logo"
			className="flex items-center ml-3 text-lg font-black tracking-normal md:ml-0 "
		>
			<Image src={Logo} height={50} width={50} alt="Jordan Esguerra Logo" />
			<h1 className="hidden ml-3 uppercase cursor-default lg:block">
				Jordan Esguerra
			</h1>
		</div>
		<ul id="nav_links" className="items-center hidden h-full md:flex">
			{desktopData.map(({ to, label, type, offset }) => (
				<NavLink key={to} to={to} label={label} type={type} offset={offset} />
			))}
		</ul>
	</Nav>
);

export default Navigation;

const Nav = styled.nav`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
